import styled from "styled-components"
import Flex from "styled-flex-component"
import is, { isOr } from "styled-is"

export const StandardContainer = styled.div`
  padding-left: ${props => props.theme.padding.hMobile};
  padding-right: ${props => props.theme.padding.hMobile};
  max-width: ${props =>
    props.wrappedNarrow
      ? props.theme.containerWidths.narrow
      : props.theme.containerWidths.standard};
  margin-left: auto;
  margin-right: auto;

  @media ${props => props.theme.breakpoints.medium} {
    padding-left: ${props => props.theme.padding.hDesk};
    padding-right: ${props => props.theme.padding.hDesk};
  }
`

export const FlexContainer = styled(Flex)`
  ${is("wrappedStandard")`
    padding-left: ${props => props.theme.padding.hMobile};
    padding-right: ${props => props.theme.padding.hMobile};
    max-width: ${props => props.theme.containerWidths.standard};
    margin-left: auto;
    margin-right: auto;
  `}
  ${is("wrappedNarrow")`
    padding-left: ${props => props.theme.padding.hMobile};
    padding-right: ${props => props.theme.padding.hMobile};
    max-width: ${props => props.theme.containerWidths.narrow};
    margin-left: auto;
    margin-right: auto;
  `}
  /* Medium Modifiers */
  @media ${props => props.theme.breakpoints.medium} {
    ${isOr("wrappedStandard", "wrappedNarrow")`
    padding-left: ${props => props.theme.padding.hDesk};
    padding-right: ${props => props.theme.padding.hDesk};
  `}
    ${is("row-m")`
    flex-direction: row; /* default */
  `};
    ${is("rowReverse-m")`
      flex-direction: row-reverse;
    `};
    ${is("column-m")`
      flex-direction: column;
    `};
    ${is("columnReverse-m")`
      flex-direction: column-reverse;
    `};
    ${is("nowrap-m")`
      flex-wrap: nowrap; /* default */
    `};
    ${is("wrap-m")`
      flex-wrap: wrap;
    `};
    ${is("wrapReverse-m")`
      flex-wrap: wrap-reverse;
    `};
    ${is("justifyStart-m")`
      justify-content: flex-start; /* default */
    `};
    ${is("justifyEnd-m")`
      justify-content: flex-end;
    `};
    ${is("justifyCenter-m")`
      justify-content: center;
    `};
    ${is("justifyBetween-m")`
      justify-content: space-between;
    `};
    ${is("justifyAround-m")`
      justify-content: space-around;
    `};
    ${is("contentStart-m")`
    align-content: flex-start;
  `};
    ${is("contentEnd-m")`
      align-content: flex-end;
    `};
    ${is("contentCenter-m")`
      align-content: center;
    `};
    ${is("contentSpaceBetween-m")`
      align-content: space-between;
    `};
    ${is("contentSpaceAround-m")`
      align-content: space-around;
    `};
    ${is("contentStretch-m")`
      align-content: stretch; /* default */
    `};
    ${is("alignStart-m")`
    align-items: flex-start;
    `};
    ${is("alignEnd-m")`
      align-items: flex-end;
    `};
    ${is("alignCenter-m")`
      align-items: center;
    `};
    ${is("alignBaseline-m")`
      align-items: baseline;
    `};
    ${is("alignStretch-m")`
      align-items: stretch;
    `};
  }
  /* Large Modifiers */
  @media ${props => props.theme.breakpoints.large} {
    ${is("row-l")`
    flex-direction: row; /* default */
  `};
    ${is("rowReverse-l")`
      flex-direction: row-reverse;
    `};
    ${is("column-l")`
      flex-direction: column;
    `};
    ${is("columnReverse-l")`
      flex-direction: column-reverse;
    `};
    ${is("nowrap-l")`
      flex-wrap: nowrap; /* default */
    `};
    ${is("wrap-l")`
      flex-wrap: wrap;
    `};
    ${is("wrapReverse-l")`
      flex-wrap: wrap-reverse;
    `};
    ${is("justifyStart-l")`
      justify-content: flex-start; /* default */
    `};
    ${is("justifyEnd-l")`
      justify-content: flex-end;
    `};
    ${is("justifyCenter-l")`
      justify-content: center;
    `};
    ${is("justifyBetween-l")`
      justify-content: space-between;
    `};
    ${is("justifyAround-l")`
      justify-content: space-around;
    `};
    ${is("contentStart-l")`
    align-content: flex-start;
  `};
    ${is("contentEnd-l")`
      align-content: flex-end;
    `};
    ${is("contentCenter-l")`
      align-content: center;
    `};
    ${is("contentSpaceBetween-l")`
      align-content: space-between;
    `};
    ${is("contentSpaceAround-l")`
      align-content: space-around;
    `};
    ${is("contentStretch-l")`
      align-content: stretch; /* default */
    `};
    ${is("alignStart-l")`
    align-items: flex-start;
    `};
    ${is("alignEnd-l")`
      align-items: flex-end;
    `};
    ${is("alignCenter-l")`
      align-items: center;
    `};
    ${is("alignBaseline-l")`
      align-items: baseline;
    `};
    ${is("alignStretch-l")`
      align-items: stretch;
    `};
  }
`
