import React from "react"
import styled from "styled-components"
import { useSpring, useTransition, config, animated } from "react-spring"

// SVGS
import BackIcon from "../../svg/arrow-left.svg"

const ModalWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  overflow-y: auto;
  @media ${props => props.theme.breakpoints.medium} and (min-height: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${props => props.theme.padding.hMobile};
  }
`

const ModalInner = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 1rem auto;
  background: ${props => props.theme.colors.docBg};
  padding: 2rem ${props => props.theme.padding.hMobile};
  position: relative;
  @media ${props => props.theme.breakpoints.medium} {
    padding: 4rem ${props => props.theme.padding.hDesk};
  }
`

const InvisibleModalButton = styled.button`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: transparent;
  display: block;
`

const ModalCloseButton = styled.button`
  width: 45px;
  position: absolute;
  right: ${props => props.theme.padding.hMobile};
  top: ${props => props.theme.padding.hMobile};
`

const AnimatedModalWrapper = animated(ModalWrapper)
const AnimatedModalInner = animated(ModalInner)

export const StandardModal = ({ children, onClose, isOpen }) => {
  const transitions = useTransition(isOpen, null, {
    from: { x: 0, y: 10 },
    enter: { x: 1, y: 0 },
    leave: { x: 0, y: 10 },
    config: config.gentle,
  })
  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <AnimatedModalWrapper
          style={{
            opacity: props.x.interpolate(x => x),
          }}
        >
          <InvisibleModalButton onClick={onClose} />
          <AnimatedModalInner
            style={{
              transform: props.y.interpolate(y => `translate3d(0,${y}%, 0)`),
            }}
          >
            <ModalCloseButton onClick={onClose}>
              <BackIcon />
            </ModalCloseButton>
            {children}
          </AnimatedModalInner>
        </AnimatedModalWrapper>
      )
  )
}
// Same as a standard modal but is allways mounted rather than transitioned
export const SpringModal = ({ children, onClose, isOpen }) => {
  const { x, y } = useSpring({
    x: isOpen ? 1 : 0,
    y: isOpen ? 0 : 10,
    z: isOpen ? 0 : 1,
    config: config.gentle,
  })
  return (
    <AnimatedModalWrapper
      style={{
        opacity: x.interpolate(x => x),
        pointerEvents: isOpen ? "all" : "none",
      }}
    >
      <InvisibleModalButton onClick={onClose} />
      <AnimatedModalInner
        style={{
          transform: y.interpolate(y => `translate3d(0,${y}%, 0)`),
        }}
      >
        <ModalCloseButton onClick={onClose}>
          <BackIcon />
        </ModalCloseButton>
        {children}
      </AnimatedModalInner>
    </AnimatedModalWrapper>
  )
}
