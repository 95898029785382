import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import useFormValidation from "./useFormValidation"
import styled from "styled-components"
//Context
import { SiteContext } from "../../context"
// Svg
import Chevron from "../../svg/chevron.svg"

// Components
import { TextBlock, StyledButton } from "../common"
import { StyledInput, StyledSelect } from "./"

// Styles
const StyledContactForm = styled.form`
  > ${TextBlock} {
    margin-bottom: 1rem;
  }
  ${StyledButton} {
    margin-top: 1rem;
  }
`

// Initial State
const INITIAL_STATE = {}

export const ContactForm = () => {
  const { setFormOpen } = useContext(SiteContext)
  const { handleSubmit, handleChange, success } = useFormValidation(
    INITIAL_STATE
  )
  const { prismicGlobals } = useStaticQuery(graphql`
    query formQuery {
      prismicGlobals {
        data {
          form_text {
            html
          }
          form_confirmation {
            html
          }
          form_industries {
            option
          }
          form_staff_numbers {
            option
          }
        }
      }
    }
  `)
  const {
    form_text,
    form_confirmation,
    form_industries,
    form_staff_numbers,
  } = prismicGlobals.data
  return (
    <StyledContactForm
      name="contact-form"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value="contact-form" />
      <p hidden>
        <label>
          Don’t fill this out:{" "}
          <input name="bot-field" onChange={handleChange} />
        </label>
      </p>
      {success ? (
        <TextBlock>
          <h2>Thank You</h2>
          <div dangerouslySetInnerHTML={{ __html: form_confirmation.html }} />
          <StyledButton large onClick={() => setFormOpen(false)}>
            Close
          </StyledButton>
        </TextBlock>
      ) : (
        <>
          <TextBlock>
            <h2>Contact us</h2>
            <div dangerouslySetInnerHTML={{ __html: form_text.html }} />
          </TextBlock>
          <StyledInput
            type="text"
            placeholder="First Name*"
            name="firstName"
            required="true"
            onChange={handleChange}
          />
          <StyledInput
            type="email"
            placeholder="Email*"
            name="email"
            required="true"
            onChange={handleChange}
          />
          <StyledInput
            type="text"
            placeholder="Company Name*"
            name="company"
            onChange={handleChange}
            required="true"
          />
          <StyledSelect>
            <select name="industry" onBlur={handleChange} required="true">
              <option disabled selected value="">
                -- Industry --
              </option>
              {form_industries.map(item => (
                <option value={item.option}>{item.option}</option>
              ))}
            </select>
            <Chevron />
          </StyledSelect>
          <StyledSelect>
            <select name="staff" onBlur={handleChange} required="true">
              <option disabled selected value="">
                -- Number of Staff --
              </option>
              {form_staff_numbers.map(item => (
                <option value={item.option}>{item.option}</option>
              ))}
            </select>
            <Chevron />
          </StyledSelect>
          <StyledInput
            type="text"
            placeholder="Country"
            name="country"
            onChange={handleChange}
            required="true"
          />
          <StyledButton type="submit" large>
            Submit
          </StyledButton>
        </>
      )}
    </StyledContactForm>
  )
}
