import styled from "styled-components"

export const StyledInput = styled.input`
  width: 100%;
  padding: 0.7rem;
  background: none;
  border: 0.1rem solid #dcdfe6;
  border-radius: 0.5rem;
  font-size: 16px;
  margin: 0.5rem 0;
  &:focus {
    outline: none;
  }
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #97a4b1;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #97a4b1;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: #97a4b1;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: #97a4b1;
  }
`

export const StyledSelect = styled.div`
  position: relative;
  margin: 0.5rem 0;
  > select {
    border: 0.1rem solid #dcdfe6;
    color: #97a4b1;
    background: #fff;
    font-size: 16px;
    border-radius: 0.5rem;
    padding: 0.7rem 2rem 0.7rem 0.7rem;
    width: 100%;
    -webkit-appearance: none;
  }
  > svg {
    width: 1.2rem;
    height: auto;
    position: absolute;
    top: 0;
    right: 1rem;
    height: 100%;
    pointer-events: none;
    fill: #97a4b1;
  }
`
