import React, { useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

// Context
import { SiteContext } from "../context"
// Styles
import { ThemeProvider } from "styled-components"
import "../css/fonts.css"
import { siteTheme } from "../themes/theme.js"
import { GlobalStyles } from "../css/globalStyles"

// Components
import { Header, Footer } from "../components/nav"
import { SpringModal } from "../components/common"
import { ContactForm } from "../components/forms"

// Styles
const PageWrapper = styled.main`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: hidden;
`

const Layout = ({ children }) => {
  const { formOpen, setFormOpen } = useContext(SiteContext)
  return (
    <ThemeProvider theme={siteTheme}>
      <GlobalStyles />
      <Header />
      <PageWrapper>
        {children}
        <Footer />
      </PageWrapper>
      <SpringModal isOpen={formOpen} onClose={() => setFormOpen(false)}>
        <ContactForm />
      </SpringModal>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
