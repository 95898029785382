import React, { useContext } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
//Context
import { SiteContext } from "../../context"

// React Spring
import { useTransition, animated, config } from "react-spring"

// Components
import Flex from "styled-flex-component"

// Styles
const StyledMobileNavLink = styled(Link)`
  color: ${props => props.theme.colors.pullColor};
  font-family: ${props => props.theme.fonts.medium};
  font-size: ${props => props.theme.fontSizes.h2};
  margin: 2vh 0;
`

const MobileNavLink = ({ url, title, ...props }) => {
  return (
    <StyledMobileNavLink to={url} {...props}>
      {title}
    </StyledMobileNavLink>
  )
}

const StyledMobileNav = styled(Flex)`
  background: ${props => props.theme.colors.docBg};
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
`

// Animated Styled Components
const AnimatedNav = animated(StyledMobileNav)

export const MobileNav = ({ items }) => {
  const { navOpen, setNavOpen, setFormOpen } = useContext(SiteContext)
  const transitions = useTransition(navOpen, null, {
    config: config.default,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <AnimatedNav alignCenter justifyCenter column style={props}>
          {items.map(item => (
            <MobileNavLink
              title={item.nav_title}
              {...item.page_reference}
              onClick={() => setNavOpen(false)}
            />
          ))}
          <StyledMobileNavLink
            as="a"
            href="https://my.actio.nz/login"
            target="_blank"
          >
            Login
          </StyledMobileNavLink>
          <StyledMobileNavLink
            as="button"
            onClick={() => {
              setNavOpen(false)
              setTimeout(() => {
                setFormOpen(true)
              }, 500)
            }}
          >
            Contact
          </StyledMobileNavLink>
        </AnimatedNav>
      )
  )
}
