import styled from "styled-components"
import is, { isNot } from "styled-is"
import { Link } from "gatsby"

export const StyledButton = styled.button.attrs({ className: `styled-button` })`
  color: #fff;
  font-family: ${props => props.theme.fonts.medium};
  background: ${props => props.theme.colors.buttonBg};
  border: 3px solid ${props => props.theme.colors.buttonBg};
  border-radius: 100px;
  padding: 0.4rem 1.5rem;
  font-size: ${props => props.theme.fontSizes.bodyReg};
  margin: 0.3rem 0;
  transition: all 0.2s ease;
  text-align: center;
  line-height: 1.3;

  ${isNot("outlined")`
    &:hover {
      background: ${props => props.theme.colors.buttonHoverBg};
      border: 3px solid ${props => props.theme.colors.buttonHoverBg};
    }
  `}
  ${is("outlined")`
    background: transparent;
    color: inherit;
    &:hover{
      background: ${props => props.theme.colors.buttonBg};
      color: #fff;
    }
  `}
  ${is("large")`
    padding: 0.4rem 3rem;
  `}
`

export const StyledButtonLink = styled(Link).attrs({
  className: `styled-button`,
})`
  color: #fff;
  font-family: ${props => props.theme.fonts.medium};
  background: ${props => props.theme.colors.buttonBg};
  border: 3px solid ${props => props.theme.colors.buttonBg};
  border-radius: 100px;
  padding: 0.4rem 1.5rem;
  font-size: ${props => props.theme.fontSizes.bodyReg};
  margin: 0.3rem 0;
  transition: all 0.2s ease;
  text-align: center;
  line-height: 1.3;
  ${isNot("outlined")`
    &:hover {
      background: ${props => props.theme.colors.buttonHoverBg};
      border: 3px solid ${props => props.theme.colors.buttonHoverBg};
    }
  `}
  ${is("outlined")`
    background: transparent;
    color: inherit;
    &:hover{
      background: ${props => props.theme.colors.buttonBg};
      color: #fff;
    }
  `}
`

export const StyledButtonAlt = styled.button.attrs({
  className: `styled-button`,
})`
  color: ${props => props.theme.colors.textColor};
  font-family: ${props => props.theme.fonts.medium};
  font-size: ${props => props.theme.fontSizes.bodyReg};
  padding: 0.5rem 1rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  &:before {
    content: "";
    width: 40px;
    height: 100%;
    background-color: ${props => props.theme.colors.tintColor};
    position: absolute;
    top: 0px;
    left: 0;
    z-index: -1;
    display: block;
    border-radius: 100px;
    transition: all 0.3s ease;
  }
  > svg {
    width: 20px;
    height: 20px;
    margin-left: 1rem;
  }
  &:hover {
    &:before {
      width: 100%;
    }
  }
  ${is("reversed")`
    color: #fff;
    > svg {
      fill: #fff;
    }
    &:before {
      background-color: #28313B;
    }
  `}
`
export const StyledLinkAlt = styled(Link).attrs({
  className: `styled-button`,
})`
  color: ${props => props.theme.colors.textColor};
  font-family: ${props => props.theme.fonts.medium};
  font-size: ${props => props.theme.fontSizes.bodyReg};
  padding: 0.5rem 1rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:before {
    content: "";
    width: 50px;
    height: 100%;
    background-color: ${props => props.theme.colors.tintColor};
    position: absolute;
    top: 0px;
    left: 0;
    z-index: -1;
    display: block;
    border-radius: 100px;
    transition: all 0.3s ease;
  }
  > svg {
    width: 20px;
    height: 20px;
    margin-left: 1rem;
  }
  &:hover {
    &:before {
      width: 100%;
    }
  }
  ${is("reversed")`
    color: #fff;
    > svg {
      fill: #fff;
    }
    &:before {
      background-color: #28313B;
    }
  `}
`

export const StyledTransparentInput = styled.input`
  background: transparent;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 0.8rem;
  color: inherit;
  font-size: ${props => props.theme.fontSizes.bodyReg};
  &:focus {
    outline: none;
    background: rgba(255, 255, 255, 0.2);
  }
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(255, 255, 255, 0.5);
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(255, 255, 255, 0.5);
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(255, 255, 255, 0.5);
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: rgba(255, 255, 255, 0.5);
  }
`
