import React from "react"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

import styled from "styled-components"

export const PrismicImage = ({ fluid, url, ...props }) => {
  return (
    <>
      {fluid ? (
        <Img fluid={fluid} {...props} />
      ) : (
        <img src={url} {...props} alt="" />
      )}
    </>
  )
}

const StyledBackground = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
`

export const PrismicBackgroundImage = ({ fluid, url, ...props }) => {
  return (
    <>
      {fluid ? (
        <BackgroundImage {...props} fluid={fluid} />
      ) : (
        <StyledBackground
          src={url}
          {...props}
          className="styled-bg"
        ></StyledBackground>
      )}
    </>
  )
}
