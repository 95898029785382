import React, { useContext } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import styled from "styled-components"

// Context
import { SiteContext } from "../../context"

// Components
import { TextBlock, FlexContainer, StyledButton, BuiltByBrave } from "../common"

const StyledFooter = styled.footer`
  width: 100%;
  background: ${props => props.theme.colors.darkBg};
`
// Styles
const FooterInner = styled.div`
  color: #fff;
  padding: 3rem 0;
  text-align: center;
  nav {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    h3 {
      width: 100%;
      margin-bottom: 1rem;
    }
    a {
      width: 50%;
      padding: 0.3rem 0;
      font-size: 16px;
    }
  }
  h2 {
    max-width: 12em;
  }
  @media ${props => props.theme.breakpoints.medium} {
    text-align: left;
    padding-left: ${props => props.theme.padding.hDesk};
    padding-right: ${props => props.theme.padding.hDesk};
    max-width: ${props => props.theme.containerWidths.standard};
    margin: 0px auto;
    nav {
      display: block;
      a {
        width: 100%;
        display: block;
      }
      h3 {
        margin-bottom: 0.5rem;
      }
    }
  }
  @media ${props => props.theme.breakpoints.large} {
    nav {
      margin-right: 5rem;
    }
  }
`
const FooterBottom = styled(FlexContainer)`
  font-size: 16px;
  margin-top: 2rem;
  position: relative;
  padding-left: ${props => props.theme.padding.hMobile};
  padding-right: ${props => props.theme.padding.hMobile};
  &:before {
    content: "";
    width: 100%;
    height: 2px;
    background: rgba(255, 255, 255, 0.3);
    margin-bottom: 2rem;
  }
  > a {
    width: 120px;
    margin-top: 0.5rem;
  }
  @media ${props => props.theme.breakpoints.medium} {
    &:before {
      display: none;
    }
    padding: 2rem 0;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
  }
`

export const Footer = () => {
  const { prismicGlobals } = useStaticQuery(graphql`
    query footerQuery {
      prismicGlobals {
        data {
          footer_quote {
            text
          }
          quick_links {
            nav_title
            link {
              url
              target
              link_type
            }
          }
          link_text
        }
      }
    }
  `)
  const { footer_quote, link_text, quick_links } = prismicGlobals.data
  const { setFormOpen } = useContext(SiteContext)

  return (
    <StyledFooter>
      <FooterInner>
        <FlexContainer column alignCenter justifyCenter row-m justifyBetween-m>
          <TextBlock>
            <h2>{footer_quote.text}</h2>
            <StyledButton onClick={() => setFormOpen(true)}>
              {link_text}
            </StyledButton>
          </TextBlock>
          <nav>
            <h3>Quick links</h3>
            {quick_links.map(item =>
              item.link.link_type === "Document" ? (
                <Link to={item.link.url}>{item.nav_title}</Link>
              ) : (
                <a href={item.link.url} target={item.link.target}>
                  {item.nav_title}
                </a>
              )
            )}
          </nav>
        </FlexContainer>
        <FooterBottom column alignCenter row-m justifyBetween-m>
          <p>&copy; Actio 2020. All Rights Reserved.</p>
          <a
            href="https://bravedigital.nz/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BuiltByBrave />
          </a>
        </FooterBottom>
      </FooterInner>
    </StyledFooter>
  )
}
