import React, { useContext } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

// Context
import { SiteContext } from "../../context"
// Components
import Headroom from "react-headroom"
import { FlexContainer } from "../common"
import { DeskNav, MobileNav, MobileToggle } from "./"
// Files / Images
import Logo from "../../svg/logo.svg"

// Styles
const HomeLink = styled(Link)`
  width: 108px;
  > svg {
    fill: ${props => props.theme.colors.pullColor};
    transition: all 0.2s ease;
  }
`
const StyledHeader = styled(Headroom)`
  z-index: 99999 !important;
  position: relative;
  header {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background: transparent;
    transition: all 0.5s ease;
  }
  .headroom--unfixed {
    header {
      ${props =>
        !props.reverseHeader &&
        !props.navOpen &&
        `
          * {
            color: #ffffff;
          }
          svg {
            fill: #ffffff;
          }
        `}
    }
  }
  .headroom--pinned {
    header {
      background: ${props => props.theme.colors.docBg};
    }
  }
`
const StyledMobileToggle = styled.button`
  svg {
    color: ${props => props.theme.colors.pullColor};
    transition: all 0.2s ease;
  }
  @media ${props => props.theme.breakpoints.large} {
    display: none;
  }
`

export const Header = () => {
  const { prismicGlobals } = useStaticQuery(graphql`
    query navQuery {
      prismicGlobals {
        data {
          main_nav {
            nav_title
            page_reference {
              url
            }
          }
        }
      }
    }
  `)
  const { main_nav } = prismicGlobals.data
  const { reverseHeader, navOpen, setNavOpen } = useContext(SiteContext)
  return (
    <>
      <StyledHeader reverseHeader={reverseHeader} navOpen={navOpen}>
        <header>
          <FlexContainer justifyBetween alignCenter wrappedStandard>
            <HomeLink to="/">
              <Logo />
            </HomeLink>
            <DeskNav items={main_nav} />
            <StyledMobileToggle onClick={() => setNavOpen(!navOpen)}>
              <MobileToggle menuState={navOpen} />
            </StyledMobileToggle>
          </FlexContainer>
        </header>
      </StyledHeader>
      <MobileNav items={main_nav} />
    </>
  )
}
