import { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`
     html {
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      overflow-x: hidden;
      box-sizing: border-box;
      font-family: ${props => props.theme.fonts.regular};
      font-size: ${props => props.theme.fontSizes.bodyReg};
      letter-spacing: 0.004rem;
      overflow-x: hidden;
      background: ${props => props.theme.colors.docBg};
      transition: background-color 1s ease;
      color: ${props => props.theme.colors.textColor};
    }


  * {
    font-weight: normal;
    word-wrap: break-word;
    box-sizing: border-box;
    font-family: ${props => props.theme.fonts.regular};
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }
  #___gatsby {
    min-height: 100vh;
  }

  body, h1, h2, h3, h4, h5, h6, p, ol, ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  strong{
    font-family: ${props => props.theme.fonts.bold};
  }

  button {
    cursor: pointer;
    background: none;
    border: none;
  }
  a{
    color: inherit;
    text-decoration: none;
  }
  
  button:focus {
    outline: none;
  }

  /* Headings */

  h1,h2,h3,h4,h5,h6{
    letter-spacing: -0.01em;
    line-height: ${props => props.theme.lineHeights.standard};
    font-family: ${props => props.theme.fonts.heading};
    margin: 0.3rem 0;
  }

  h1 {
    font-size: ${props => props.theme.fontSizes.h1};
    line-height: ${props => props.theme.lineHeights.heading};
  }
  h2 {
    font-size: ${props => props.theme.fontSizes.h2};
    line-height: ${props => props.theme.lineHeights.heading};
  }
  h3{
    font-size: ${props => props.theme.fontSizes.h3};
  }
  h4{
    font-size: ${props => props.theme.fontSizes.h4};
  }
  h5{
    font-size: ${props => props.theme.fontSizes.h4};
    color: ${props => props.theme.colors.pullColor}
  }
  

  /* body {
  font-size: calc(32px + (45px - 32px) * ((100vw - 320px) / (2180 - 320px)));
}
body {
  font-size: calc(32px + (55 - 32) * ((100vw - 320px) / (1600 - 320)));
}

body {
  font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])));
} */
`
