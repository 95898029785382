import styled from "styled-components"
import is from "styled-is"

export const TextBlock = styled.div`
  font-size: ${props => props.theme.fontSizes.bodyReg};
  line-height: ${props => props.theme.lineHeights.standard};
  p{
    padding: 0.5rem 0;
    font-size: ${props => props.theme.fontSizes.bodyReg};
  }
  > .styled-button {
     margin-top: 0.8rem;
     display: inline-block;
  }
  ul,
  ol {
    list-style-position: inside;
  }
  ${is("measure-s")`
    >  h1,
        h2,
        h3,
        h4,
        h5,
        h6,p {
          max-width: ${props => props.theme.measures.standard};
        }
  `}
  ${is("measure-m")`
    >  h1,
        h2,
        h3,
        h4,
        h5,
        h6,p {
          max-width: ${props => props.theme.measures.medium};
        }
  `}
  ${is("measure-l")`
    >  h1,
        h2,
        h3,
        h4,
        h5,
        h6,p {
          max-width: ${props => props.theme.measures.large};
        }
  `}
`
