export const siteTheme = {
  breakpoints: {
    ns: "screen and (min-width: 30em)",
    medium: "screen and (min-width: 30em)",
    large: "screen and (min-width: 64em)",
    xl: "screen and (min-width: 94em)",
  },
  colors: {
    docBg: "#FFFFFF",
    headingColor: "#343434",
    textColor: "#4D4D4D",
    pullColor: "#0630F8",
    tintColor: "#EEEEEE",
    borderStandard: "#E0E9F4",
    darkBg: "#111F2A",
    buttonBg: "#0630F8",
    buttonHoverBg: "#00A1FF",
  },
  fonts: {
    regular: "axiformaregular",
    medium: "axiformamedium",
    semiBold: "axiformasemibold",
    bold: "axiformasemibold",
    heading: "axiformamedium",
  },
  fontSizes: {
    bodyReg: "18px",
    h1: "calc(32px + (45 - 32) * ((100vw - 320px) / (1800 - 320)))",
    h2: "calc(28px + (32 - 28) * ((100vw - 320px) / (1800 - 320)))",
    h3: "calc(19px + (21 - 19) * ((100vw - 320px) / (1800 - 320)))",
    h4: "18px",
  },
  lineHeights: {
    standard: "1.8",
    heading: "1.5",
  },
  letterSpacing: {
    standard: "0",
    nav: "0.81px",
  },
  measures: {
    large: "55em",
    medium: "45em",
    standard: "30em",
  },
  padding: {
    hMobile: "1.4rem",
    hDesk: "4rem",
  },
  containerWidths: {
    narrow: "1200px",
    standard: "1360px",
  },
  pageSpacers: {
    small: "calc(15px + (100 - 15) * ((100vw - 320px) / (1800 - 320)))",
    medium: "calc(30px + (100 - 30) * ((100vw - 320px) / (1800 - 320)))",
    large: "calc(50px + (100 - 50) * ((100vw - 320px) / (1800 - 320)))",
  },
}
