import React from "react"
import { useSpring, animated, config } from "react-spring"
import styled from "styled-components"

const StyledMobileToggle = styled.svg`
  overflow: visible;
  cursor: pointer;
  position: relative;
  font-size: 2rem;
`

export const MobileToggle = ({ menuState }) => {
  const { x } = useSpring({
    x: menuState ? 1 : 0,
    config: config.wobbly,
  })
  return (
    <StyledMobileToggle
      viewBox="0 0 96 96"
      height="1em"
      className="b-hamburger"
    >
      <g
        id="navicon"
        fill="none"
        stroke="currentColor"
        strokeWidth="10"
        strokeLinecap="square"
        strokeLinejoin="square"
      >
        <animated.line
          x1="7"
          y1="26"
          x2="89"
          y2="26"
          transform={x.interpolate(
            x => `translate(${x * 12}, ${x * -7}) rotate(${x * 45}, 7, 26)`
          )}
        />
        <animated.line
          x1="7"
          y1="70"
          x2="89"
          y2="70"
          transform={x.interpolate(
            x => `translate(${x * 12}, ${x * 7}) rotate(${x * -45}, 7, 70)`
          )}
        />
      </g>
    </StyledMobileToggle>
  )
}
