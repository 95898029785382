import React, { useContext } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

// Context
import { SiteContext } from "../../context"

// Components
import { FlexContainer, StyledButton } from "../common"

// Styles
const StyledNavLink = styled(Link)`
  text-decoration: none;
  font-family: ${props => props.theme.fonts.medium};
  font-size: 16px;
`

const StyledDeskNav = styled(FlexContainer)`
  display: none;
  ${StyledButton}, ${StyledNavLink} {
    margin-left: 1.5rem;
  }
  > ${StyledButton} {
    font-size: 16px;
  }
  @media ${props => props.theme.breakpoints.large} {
    display: flex;
  }
`

const DeskNavLink = ({ url, title }) => {
  return <StyledNavLink to={url}>{title}</StyledNavLink>
}

export const DeskNav = ({ items }) => {
  const { setFormOpen } = useContext(SiteContext)
  return (
    <StyledDeskNav as="nav" alignCenter>
      {items.map(item => (
        <DeskNavLink title={item.nav_title} {...item.page_reference} />
      ))}
      <StyledButton
        outlined
        as="a"
        href="https://my.actio.nz/login"
        target="_blank"
      >
        Login
      </StyledButton>
      <StyledButton onClick={() => setFormOpen(true)}>Contact Us</StyledButton>
    </StyledDeskNav>
  )
}
